import React, { useEffect } from "react"
import AemGridColumn from "@/components/AemGridColumn"
import BrandHeader from "@/components/Shared/BrandHeader"
import HeaderNav from "@/components/Shared/HeaderNav"
import LogoHeader from "@/components/Shared/LogoHeader"
import Video from "@/components/Default/Video/v1/Video"
import Carousel from "@/components/Default/Carousel/v1/Carousel"
import Footer from "@/components/Shared/Footer"
import HelpSupport from "@/components/HelpSupport/v1/HelpSupport"
import Script from "next/script"
import {
  isAemForm,
  isBrandHeader,
  isCarousel,
  isFooter,
  isHeaderNav,
  isSlick,
  isTab,
  isTeaser,
  isText,
  isVideo,
  isImage,
  isCartLanding,
  isPairsWellWth,
  isCheckoutLanding,
  isEmbed,
  isSharedCart,
  isOrderConfirmation,
  isOrderDetails,
  isOrderHistory,
  isOrderStatus,
  isAuthModal,
  isNewsLetter,
  isSpacer,
  ConditionalWrapper,
  isBreadcrumb,
  isProductList,
  isProductDetails,
  isSearch,
  isSiteWideWarning,
  isPromoBanner,
  isToaster,
  isCompareProducts,
  isTeaserContainer,
  isNewsLetterSignUp,
  isHelpSupport,
  isAccountSettings,
  isMyservice,
  isProductAccordion,
  isProductReview,
  isRegisterOffline,
  isRegisterOnline,
  isMyFavorites,
  isMyproducts,
  isProductcard,
  isProductcardV3,
  isProductCardV4,
  isProductExfPdp,
  isKnowledgeArticle,
  isBrowseAllStores,
  isLiterature,
  isContactUs,
  isFindingModelNumber,
  isLogoHeader,
  isBrandNav,
  recursiveDataFunction,
  isTeaserV3,
  isChangePassword,
  isTeaserV2,
  isBlogCarousel,
  isBlogListing,
  isLeadGeneration,
  isInspirationLanding,
  isInspirationDetail,
  isFindAStoreLandingV2,
  isStoreFilter,
  isManualPDP,
  isManualPDP_TABS,
  isAsProductList,
  isPressLanding,
  isTradeMembership,
  isProductTabDetails,
  isInPageSearch,
  isTechnicalSpec,
  isTitle,
  isButton,
  isList
} from "@/utils"
import Teaser from "@/components/Default/Teaser/v1/Teaser"
import Text from "@/components/Default/Text/v1/Text"
import Tab from "@/components/Default/Tab"

import { setAuthModalStaticText } from "@/store/features/authSlice"
import {
  setSubscribeModalContent,
  setSitewideWarningContent,
  setCompareproductsStaticText,
  setSitewideNewsLetterContent,
  setToastContent,
} from "@/store/features/genericSlice"
import { useDispatch } from "react-redux"

import Embed from "@/components/Shared/Embed"
import Spacer from "@/components/Default/Spacer/v1/Spacer"
import PromoBanner from "@/components/Default/PromoBanner/v1/PromoBanner"

import useIsSsr from "@/hooks/useIsSsr"
import AemForm from "@/components/Default/AemForm"
import ProductCard from "@/components/productCard/v1/ProductCard"
import ProductCardV3 from "@/components/productCard/v3/ProductCard"
import FindingModelNumber from "@/components/FindingModelNumber/v1/FindingModelNumber"
import BrandNav from "./Shared/BrandNav"
import TeaserV2 from "./Default/Teaser/v2/Teaser"
import BlogCarousel from "@/components/Blog/BlogCarousel/v1/BlogCarousel"
import BlogListing from "@/components/Blog/BlogList/v1/BlogList"
import { setStoreFilter } from "@/store/features/locationSlice"
import CarouselSlick from "./CarouselSlick/v1/CarouselSlick"
import ManualPdp from "@/components/ManualPdp"
import AsProductTabDetail from "@/components/AsProductTabDetail"
import ProductList from "@/components/ProductList/v1/ProductList"
import ImageV1 from "./Default/Image/v1/Image"
import InPageSearch from "@/components/InPageSearch/v1/InPageSearch"
import ProductAccordion from "@/components/ProductDetail/v3/ProductAccordion"
import Title from "@/components/Default/Title"
import Button from "@/components/core/Button/Button"
import ProductCardForUpsell from "@/components/Shared/UpsellInstallServices/ProductCardForUpsell"
import ListComponent from "@/components/List/v1/List"

const AemGrid = props => {
  const {
    data = {},
    itemKey = "",
    count = 0,
    eventHandler = {},
    fullData = {},
    containerId = "",
    ...extraProps
  } = props
  const dispatch = useDispatch()
  const isSsr = useIsSsr()
  const {
    changePassword = () => {},
    contactUs = () => {},
    technicalSpecifications = () => {},
  } = extraProps
  useEffect(() => {
    if (isAuthModal(data)) {
      dispatch(setAuthModalStaticText(data))
    }

    if (isCompareProducts(data)) {
      dispatch(setCompareproductsStaticText(data))
    }

    if (isNewsLetter(data)) {
      dispatch(setSubscribeModalContent(data))
    }

    if (isSiteWideWarning(data)) {
      dispatch(setSitewideWarningContent(data))
    }
    if (isNewsLetterSignUp(data)) {
      dispatch(setSitewideNewsLetterContent(data))
    }
    if (isToaster(data)) {
      dispatch(setToastContent(data))
    }
  }, [data])

  if (isVideo(data)) {
    return <Video data={data} />
  }

  if (isButton(data)){
    return <Button data={data} label={data.text} className={data.appliedCssClassNames}/>
  }

  if (isSpacer(data)) {
    return <Spacer data={data} />
  }

  if (isAemForm(data)) {
    return <AemForm data={data} fullData={fullData} />
  }
  if (isManualPDP(data)) {
    return <ManualPdp data={data} fullData={fullData} />
  }
  if (isManualPDP_TABS(data)) {
    return <AsProductTabDetail data={data} fullData={fullData} />
  }
  if (isProductcard(data)) {
    return <ProductCard data={data} fullData={fullData} />
  }
  if (isBlogCarousel(data) && !isSsr) {
    return <BlogCarousel data={data} />
  }
  if (isBlogListing(data) && !isSsr) {
    return <BlogListing data={data} />
  }
  if (isAsProductList(data) && !isSsr) {
    return <ProductList data={data} />
  }
  if (isCarousel(data)) {
    return <Carousel data={data} fullData={fullData} />
  }

  if (isPromoBanner(data)) {
    return <PromoBanner data={data} />
  }

  if(isTitle(data)){
    return <Title data={data} />
  }

  if (isSlick(data)) {
    return <CarouselSlick fullData={fullData} data={data} />
  }

  if (isTeaserV2(data)) {
    return <TeaserV2 data={data} />
  }
  if (isTeaser(data)) {
    return <Teaser data={data} fullData={fullData} />
  }
  if (isProductCardV4(data)) {
    return !isSsr ? <ProductCardForUpsell authData={data} /> : null
  }

  if (isProductcardV3(data)) {
    const { skewId = "", presetConfigs = "" } = data
    return !isSsr ? (
      <div className="productcard-v3 productcard">
        <ProductCardV3
          authorData={{ skewId, presetConfigs }}
          fullData={fullData}
        />
      </div>
    ) : null
  }

  if (isText(data)) {
    return <Text data={data} />
  }

  if (isTab(data) && data["appliedCssClassNames"]) {
    return <Tab data={data} />
  }

  if (isBrandHeader(data)) {
    return <BrandHeader data={data} eventHandler={eventHandler} />
  }

  if (isHeaderNav(data)) {
    return <HeaderNav data={data} eventHandler={eventHandler} />
  }

  if (isEmbed(data)) {
    return <Embed data={data} eventHandler={eventHandler} />
  }

  if (isFooter(data)) {
    return <Footer data={data} eventHandler={eventHandler} />
  }

  if (isLogoHeader(data)) {
    return <LogoHeader data={data} eventHandler={eventHandler} />
  }

  if (isBrandNav(data)) {
    return <BrandNav data={data} eventHandler={eventHandler} />
  }
  if (isImage(data)) {
    return <ImageV1 data={data} fullData={fullData} />
  }
  if (isCartLanding(data)) {
    if (!isSsr) {
      return extraProps && extraProps.landing && extraProps.landing(data)
    } else {
      return (
        extraProps && extraProps.landingFiller && extraProps.landingFiller(data)
      )
    }
  }

  if (isInPageSearch(data)) {
    return !isSsr ? <InPageSearch data={data} /> : null
  }

  if (isLeadGeneration(data)) {
    return (
      extraProps && extraProps.leadGeneration && extraProps.leadGeneration(data)
    )
  }
  if (isInspirationLanding(data)) {
    return (
      extraProps &&
      extraProps.inspirationLanding &&
      extraProps.inspirationLanding(data)
    )
  }
  if (isInspirationDetail(data)) {
    return (
      extraProps &&
      extraProps.inspirationDetail &&
      extraProps.inspirationDetail(data)
    )
  }
  if (isPressLanding(data) && !isSsr) {
    return (
      extraProps &&
      extraProps.pressReleaseLanding &&
      extraProps.pressReleaseLanding(data)
    )
  }

  if (isTradeMembership(data)) {
    return (
      extraProps &&
      extraProps.tradeMembership &&
      extraProps.tradeMembership(data)
    )
  }

  if (isCheckoutLanding(data) && !isSsr) {
    return (
      extraProps && extraProps.checkoutView && extraProps.checkoutView(data)
    )
  }

  if (isOrderConfirmation(data)) {
    if (!isSsr) {
      return (
        extraProps &&
        extraProps.orderConfirmation &&
        extraProps.orderConfirmation(data)
      )
    } else {
      return (
        extraProps &&
        extraProps.orderConfirmationTagFiller &&
        extraProps.orderConfirmationTagFiller()
      )
    }
  }

  if (isOrderStatus(data)) {
    if (!isSsr) {
      return (
        extraProps && extraProps.orderStatus && extraProps.orderStatus(data)
      )
    } else {
      return (
        extraProps &&
        extraProps.orderStatusFiller &&
        extraProps.orderStatusFiller()
      )
    }
  }

  if (isOrderHistory(data) && !isSsr) {
    return (
      extraProps && extraProps.orderHistory && extraProps.orderHistory(data)
    )
  }

  if (isOrderDetails(data)) {
    if (!isSsr) {
      return (
        extraProps && extraProps.orderDetails && extraProps.orderDetails(data)
      )
    } else {
      return (
        extraProps &&
        extraProps.orderDetailsFiller &&
        extraProps.orderDetailsFiller(data)
      )
    }
  }

  if (isPairsWellWth(data) && !isSsr) {
    return (
      extraProps && extraProps.pairsWellWith && extraProps.pairsWellWith(data)
    )
  }

  if (isSharedCart(data) && !isSsr) {
    return extraProps && extraProps.sharedCart && extraProps.sharedCart(data)
  }

  if (isBreadcrumb(data)) {
    return (
      extraProps &&
      extraProps.breadCrumb &&
      extraProps.breadCrumb(data, fullData)
    )
  }

  if (isProductList(data)) {
    if (!isSsr) {
      return (
        extraProps &&
        extraProps.productList &&
        extraProps.productList(data, eventHandler)
      )
    } else {
      return (
        extraProps &&
        extraProps.productListFiller &&
        extraProps.productListFiller(data)
      )
    }
  }
  if (isSearch(data)) {
    if (!isSsr) {
      return (
        extraProps &&
        extraProps.searchComponent &&
        extraProps.searchComponent(data, eventHandler)
      )
    } else {
      return (
        extraProps && extraProps.searchFiller && extraProps.searchFiller(data)
      )
    }
  }

  if (isHelpSupport(data) && !isSsr) {
    return <HelpSupport data={data} />
  }

  if (isAccountSettings(data) && !isSsr) {
    return (
      extraProps &&
      extraProps.accountSettings &&
      extraProps.accountSettings(data)
    )
  }
  if (isMyservice(data) && !isSsr) {
    return extraProps && extraProps.myServices && extraProps.myServices(data)
  }
  if (isMyFavorites(data) && !isSsr) {
    return extraProps && extraProps.myFavorites && extraProps.myFavorites(data)
  }

  if (isMyproducts(data)) {
    if (!isSsr) {
      return extraProps && extraProps.myProducts && extraProps.myProducts(data)
    } else {
      return (
        extraProps &&
        extraProps.myProductsFiller &&
        extraProps.myProductsFiller()
      )
    }
  }
  if (isLiterature(data) && !isSsr) {
    return extraProps && extraProps.literature && extraProps.literature(data)
  }

  if (isProductDetails(data)) {
    if (!isSsr) {
      return (
        extraProps &&
        extraProps.productDetail &&
        extraProps.productDetail(
          data,
          extraProps?.slug,
          extraProps?.skuId,
          extraProps?.type
        )
      )
    } else {
      return (
        extraProps &&
        extraProps.productDetailFiller &&
        extraProps.productDetailFiller(data)
      )
    }
  }
  if (isProductAccordion(data) && !isSsr) {
    return <ProductAccordion data={data} />
  }

  if (isProductAccordion(data) && !isSsr) {
    return (
      extraProps &&
      extraProps.productAccordion &&
      extraProps.productAccordion(data)
    )
  }

  if (isProductExfPdp(data) && !isSsr) {
    return extraProps && extraProps.productExf && extraProps.productExf(data)
  }

  if (isProductReview(data) && !isSsr) {
    return extraProps && extraProps.review && extraProps.review(data)
  }

  if (isRegisterOffline(data)) {
    if (!isSsr) {
      return (
        extraProps &&
        extraProps.offlineProducts &&
        extraProps.offlineProducts(data)
      )
    } else {
      return (
        extraProps &&
        extraProps.offlineProducts &&
        extraProps.offlineProductsFiller()
      )
    }
  }

  if (isRegisterOnline(data)) {
    if (!isSsr) {
      return (
        extraProps &&
        extraProps.onlineProducts &&
        extraProps.onlineProducts(data)
      )
    } else {
      return (
        extraProps &&
        extraProps.onlineProducts &&
        extraProps.offlineProductsFiller()
      )
    }
  }

  if (isKnowledgeArticle(data)) {
    if (!isSsr) {
      return (
        extraProps &&
        extraProps.knowledgeArticle &&
        extraProps.knowledgeArticle(data)
      )
    } else {
      return (
        extraProps &&
        extraProps.knowledgeArticleFiller &&
        extraProps.knowledgeArticleFiller()
      )
    }
  }

  if (isBrowseAllStores(data) && !isSsr) {
    return (
      extraProps &&
      extraProps.browseAllStores &&
      extraProps.browseAllStores(data)
    )
  }
  if (isFindAStoreLandingV2(data) && !isSsr) {
    return (
      extraProps && extraProps.findashowroom && extraProps.findashowroom(data)
    )
  }
  if (isStoreFilter(data)) {
    dispatch(setStoreFilter(data))
  }

  if (isContactUs(data) && !isSsr) {
    return contactUs(data)
  }

  if (isFindingModelNumber(data) && !isSsr) {
    return <FindingModelNumber data={data} />
  }

  if (isChangePassword(data)) {
    if (!isSsr) {
      return changePassword(data)
    } else {
      return "Loading..."
    }
  }

  if (isProductTabDetails(data)) {
    if (!isSsr) {
      return (
        extraProps &&
        extraProps.productTabDetails &&
        extraProps.productTabDetails(data)
      )
    }
  }
  if (isProductDetails(data)) {
    if (!isSsr) {
      return (
        extraProps &&
        extraProps.productDetail &&
        extraProps.productDetail(data, extraProps?.slug, extraProps?.skuId)
      )
    } else {
      return (
        extraProps &&
        extraProps.productDetailFiller &&
        extraProps.productDetailFiller(data)
      )
    }
  }
  if (isTechnicalSpec(data)) {
    return technicalSpecifications && technicalSpecifications(data)
  }
  if (isList(data)) {
    return <ListComponent data={data} />
  }
  return (
    <>
      <ConditionalWrapper
        condition={
          data?.[":itemsOrder"] &&
          data?.[":itemsOrder"]?.length === 1 &&
          data?.[":itemsOrder"]?.includes("text") &&
          !isTeaserContainer(data)
        }
        wrapper={children => (
          <div className={data?.gridClassNames}>{children}</div>
        )}
      >
        <AemGridColumn
          data={data}
          itemKey={itemKey}
          count={count}
          eventHandler={eventHandler}
          containerId={containerId}
          {...extraProps}
        />
      </ConditionalWrapper>
      {/* <!-- Meta Pixel Code --> */}
      <Script id="fb-pixel" strategy="afterInteractive" dangerouslySetInnerHTML={{
        __html: `
        !function(f,b,e,v,n,t,s)
        {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
        n.callMethod.apply(n,arguments):n.queue.push(arguments)};
        if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
        n.queue=[];t=b.createElement(e);t.async=!0;
        t.src=v;s=b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t,s)}(window,document,'script',
        'https://connect.facebook.net/en_US/fbevents.js');
        fbq('init', '682865165183330');
        fbq('track', 'PageView');
        `
        ,
      }}
      />
      <noscript>
        <img height="1" width="1"
        src="https://www.facebook.com/tr?id=682865165183330&ev=PageView
        &noscript=1"/>
      </noscript>

      {/* <!-- Google tag (gtag.js) --> */}
      <Script async src="https://www.googletagmanager.com/gtag/js?id=G-8HLNZJV900">
      </Script> 
      <Script
        id="google-analytics"
        dangerouslySetInnerHTML={{
        __html: `
        window.dataLayer = window.dataLayer || []; 
        function gtag(){dataLayer.push(arguments);} gtag('js', new Date()); gtag('config', 'G-8HLNZJV900'); `
        ,
      }}
      />
    </>
  )
}

export default AemGrid
