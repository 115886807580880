import React, { useState, useEffect } from "react"
import DOMPurify from "isomorphic-dompurify"
import _isEmpty from "lodash/isEmpty"

import useIsSsr from "@/hooks/useIsSsr"
import {
  convertStringToBoolean,
  createIdFromString,
  randomWholeNumber,
} from "@/utils/helper"
import { getConfig } from "@/constants/config"
import { getShortenedUrl } from "@/utils/helper"
import Description from "@/components/Default/Teaser/v1/Description"
import Action from "@/components/Default/Teaser/v1/Action"
import { COMPONENT_TYPES } from "@/constants/index"

const Teaser = props => {
  const [dynamicId, setDynamicId] = useState("")
  const {
    data: {
      id = "",
      appliedCssClassNamesCustom = "",
      linkURLRef = false,
      linkAll = false,
      description = "",
      mainTitle = "",
      pageType = "",
      linkURL = "",
      caption = "",
      actions = [],
      imageURLWithoutCrop = "",
      smartCrops = [],
      imageResource: { assetID = "", pretitle: preTitle = "" } = {},
    } = {},
    fullData = {},
    categoryName,
  } = props

  const [parentActionLink, setParentActionLink] = useState(linkURL)
  const [componentGroup, setComponentGroup] = useState([])
  const [columnClassNames, setColumnClassNames] = useState("")
  const [general, setGeneral] = useState(null)
  const [item, setItem] = useState()
  const isSsr = useIsSsr()
  const imageURL = smartCrops?.length && smartCrops[0].imageURL
  useEffect(() => {
    console.log("Inside Teaser V1");
    getConfig().then(config => {
      setGeneral(config?.general)
    })
  }, [])
  useEffect(() => {
    if (assetID) {
      const dynamicId = createIdFromString(assetID)

      setDynamicId(dynamicId)
    } else {
      const dynamicId = createIdFromString(
        Math.floor(Math.random() * 100000) + ""
      )
      setDynamicId(dynamicId)
    }
  }, [assetID])
  useEffect(() => {
    if (linkURL) {
      getShortenedUrl(linkURL).then(res => {
        setParentActionLink(res)
      })
    }
  }, [linkURL])

  useEffect(() => {
    if (!_isEmpty(fullData)) {
      Object.keys(fullData).forEach(key => {
        setComponentGroup(cmpGroup => [...cmpGroup, fullData[key][":type"]])
      })
    }
  }, [fullData])

  useEffect(() => {
    if (componentGroup.length > 0) {
      if (
        componentGroup.find(a => a?.includes(COMPONENT_TYPES.BREADCRUMB)) &&
        componentGroup.find(a => a?.includes(COMPONENT_TYPES.TEASER)) &&
        appliedCssClassNamesCustom.includes("hero-banner-article-top") &&
        !appliedCssClassNamesCustom.includes("hero-banner-article-bottom") &&
        !columnClassNames?.includes(
          "hero-banner-top-only paddingbasedbreadcrum"
        )
      ) {
        setColumnClassNames(
          className =>
            (className += "hero-banner-top-only paddingbasedbreadcrum")
        )
      } else if (
        ((componentGroup.find(a => a?.includes(COMPONENT_TYPES.BREADCRUMB)) &&
          componentGroup.find(a => a?.includes(COMPONENT_TYPES.TEASER))) ||
          appliedCssClassNamesCustom.includes("sticky-pagescroll-nav")) &&
        !columnClassNames?.includes(
          "aem-GridColumn aem-GridColumn--default--12"
        )
      ) {
        setColumnClassNames(
          className =>
            (className += " aem-GridColumn aem-GridColumn--default--12")
        )
      }
    }
  }, [componentGroup])

  return (
    <>
      <div className="cmp-container" style={{ position: "static" }}>
        <div className="teaser_v3 teaser annsacks-navigation-teaser link-active">
          <div className="cmp-teaser">
            <div>
              <div>
                <div className="cmp-image">
                  <div className="cmp-image">
                    {!isSsr && (
                      <img
                        className="fluidimage cmp-image__image"
                        src={imageURLWithoutCrop || smartCrops?.[0]?.imageURL}
                        alt={caption}
                        itemProp="contentUrl"
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
            {description && (
              <div className="cmp-teaser__content">
                <Description
                  linkURLRef={linkURLRef}
                  description={description}
                />
                {actions && actions?.length > 0 && (
                  <div className="cmp-teaser__action-container">
                    {actions.map((action, index) => {
                      const {
                        url = "",
                        text = "",
                        newTab = "",
                        urlRef = "",
                      } = action
 
                      return (
                        <Action
                          key={`teaser-action-${index + 1}-${randomWholeNumber(
                            250,
                            499
                          )}`}
                          link={url}
                          text={text}
                          newTab={convertStringToBoolean(newTab)}
                          urlRef={urlRef}
                          id={id}
                          pageType={pageType}
                          linkAll={convertStringToBoolean(!linkAll)}
                          mainTitle={mainTitle}
                        />
                      )
                    })}
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
        <div
          className="cmp-img-caption"
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(caption),
          }}
        />
      </div>
      <div className="ann-mob-nav">
        <a
          className="nav-link"
          aria-hidden="false"
          href={linkURL.replace(general?.shortendPagePath,"").replace(".html","")}
          rel="noreferrer"
          aria-selected="true"
          role="tab"
          tabIndex="0"
          data-gbh-clickable="true"
        >
          {categoryName}
        </a>
      </div>
    </>
  )
}

export default Teaser
